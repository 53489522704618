<template>
  <div>
    <v-row class="pa-5">
      <span style="font-size: 25px">TPO Registration Dates</span>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-row>

    <v-card>
      <v-row justify="end" class="px-5 py-2">
        <v-btn class="pa-5 primary" @click="dialog = !dialog">
          Add TPO Registration Dates
        </v-btn>
      </v-row>
      <v-simple-table
        class="pa-3"
        v-if="
          Array.isArray(tporegistration_master_list) &&
            tporegistration_master_list.length
        "
      >
        <thead>
          <th style="font-size: 13px" class="text-left grey--text">
            Academic Year
          </th>
          <th style="font-size: 13px" class="text-left grey--text">
            From Date
          </th>
          <th style="font-size: 13px" class="text-left grey--text">To Date</th>
          <th style="font-size: 13px" class="text-left grey--text">Year</th>
          <th style="font-size: 13px" class="text-left grey--text">Active</th>
          <th style="font-size: 13px" class="text-left grey--text">Actions</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tporegistration_master_list" :key="index">
            <td>
              {{ item.ay }}
            </td>
            <td>
              {{ item.from_date }}
            </td>
            <td>
              {{ item.to_date }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              <v-switch
                v-model="tporegistration_master_list[index].isactive"
                @change="activeswitch(item)"
                :labels="{ checked: 'On', unchecked: 'Off' }"
              ></v-switch>
            </td>
            <td>
              <div>
                <v-avatar style="" size="25" class="edit-avatar">
                  <v-icon class="edit-v-icon mr-2" @click="editItem(index)"
                    >edit</v-icon
                  >
                </v-avatar>
                <v-btn text>
                  <v-icon color="red" @click="delete1(item.id)">
                    mdi-delete</v-icon
                  ></v-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-dialog max-width="600px" v-model="dialog">
      <v-card>
        <v-row class="px-5 py-2" justify="space-between">
          <span class="headline"> Add TPO Registration Dates </span>

          <v-icon @click="dialog = !dialog">mdi-close </v-icon>
        </v-row>
        <v-row class="pa-2" justify="space-around">
          <v-col cols="12" sm="4">
            <label> Academic Year</label>
            <v-select :items="aylist" v-model="ay"></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <label> From Date</label><br />
            <input
              style="border: 1px solid grey"
              type="date"
              v-model="from_date"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label> TO Date</label><br />
            <input
              style="border: 1px solid grey"
              type="date"
              v-model="to_date"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label> Year</label>
            <v-select :items="yearlist" v-model="name"></v-select>
          </v-col>
        </v-row>
        <v-row class="px-10" justify="end">
          <v-checkbox
            label="Isactive"
            color="success"
            v-model="isactive"
          ></v-checkbox>
        </v-row>
        <v-card-actions>
          <v-row class="px-5" justify="end">
            <v-btn
              color="blue darken-1"
              @click="dialog = !dialog"
              class="ma-1"
              outlined
              text
              >Cancel</v-btn
            >
            <v-btn @click="save()" class="ma-1 primary">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="dialog1">
      <v-card>
        <v-row class="px-5 py-2" justify="space-between">
          <span class="headline"> Edit TPO Registration Dates </span>

          <v-icon @click="dialog1 = !dialog1">mdi-close </v-icon>
        </v-row>
        <v-row class="pa-2" justify="space-around">
          <v-col cols="12" sm="4">
            <label> Academic Year</label>
            <v-select :items="aylist" disabled v-model="param.ay"></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <label> From Date</label><br />
            <input
              style="border: 1px solid grey"
              type="date"
              v-model="param.from_date"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label> TO Date</label><br />
            <input
              style="border: 1px solid grey"
              type="date"
              v-model="param.to_date"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label> Year</label>
            <v-select
              :items="yearlist"
              disabled
              v-model="param.name"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="px-10" justify="end">
          <v-checkbox
            label="Isactive"
            color="success"
            v-model="param.isactive"
          ></v-checkbox>
        </v-row>
        <v-card-actions>
          <v-row class="px-5" justify="end">
            <v-btn
              color="blue darken-1"
              @click="dialog1 = !dialog1"
              class="ma-1"
              outlined
              text
              >Cancel</v-btn
            >
            <v-btn @click="edit()" class="ma-1 primary">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../shared/Header.vue";
import moment from "moment";

import Language from "./Language.vue";

export default {
  components: { Header, Language },
  data() {
    return {
      dialog: false,
      dialog1: false,
      snackbar_msg: "",
      color: "",
      snackbar: false,
      tporegistration_master_list: [],
      aylist: [],
      yearlist: [],
      name: "",
      ay: "",
      // from_date: new Date(),
      from_date: new Date().toISOString().slice(0, 10),
      to_date: new Date().toISOString().slice(0, 10),
      // to_date: new Date(),
      isactive: true,
      param: {
        ay: "",
        name: "",
        from_date: new Date(),
        to_date: new Date(),
        isactive: false,
      },
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    editItem(index) {
      this.param.ay = this.tporegistration_master_list[index].ay;
      this.param.name = this.tporegistration_master_list[index].name;
      this.param.id = this.tporegistration_master_list[index].id;
      // this.param.to_date = moment(
      //   this.tporegistration_master_list[index].to_date
      // ).format("YYYY-DD-MM");
      this.param.to_date =
        this.tporegistration_master_list[index].to_date.split("-")[2] +
        "-" +
        this.tporegistration_master_list[index].to_date.split("-")[1] +
        "-" +
        this.tporegistration_master_list[index].to_date.split("-")[0];
      //console.log("in edit")
      // this.param.from_date = moment(
      //   this.tporegistration_master_list[index].from_date
      // ).format("YYYY-DD-MM");
      this.param.from_date =
        this.tporegistration_master_list[index].from_date.split("-")[2] +
        "-" +
        this.tporegistration_master_list[index].from_date.split("-")[1] +
        "-" +
        this.tporegistration_master_list[index].from_date.split("-")[0];
      // console.log("this.tporegistration_master_list[index].to_date");
      // console.log(this.tporegistration_master_list[index].to_date);
      // console.log(this.param.to_date)
      this.param.isactive = this.tporegistration_master_list[index].isactive;
      this.dialog1 = true;
    },
    init() {
      
      axios.post("/AdminMaster/addOpenTpoRegistration").then((res) => {
        if (res.data.status == "200") {
          this.tporegistration_master_list =
            res.data.tporegistration_master_list;
          //console.log(this.tporegistration_master_list);
          for (var index in this.tporegistration_master_list) {
            this.tporegistration_master_list[
              index
            ].ay = this.tporegistration_master_list[index].ay;
            this.tporegistration_master_list[
              index
            ].name = this.tporegistration_master_list[index].name;
            this.tporegistration_master_list[index].to_date = moment(
              this.tporegistration_master_list[index].to_date
            ).format("DD-MM-YYYY");

            this.tporegistration_master_list[index].from_date = moment(
              this.tporegistration_master_list[index].from_date
            ).format("DD-MM-YYYY");
            this.tporegistration_master_list[
              index
            ].isactive = this.tporegistration_master_list[index].isactive;
          }
          // console.log(this.tporegistration_master_list);

          this.aylist = res.data.aylist;
          this.yearlist = res.data.yearlist;
        } else {
          this.aylist = res.data.aylist;
          this.yearlist = res.data.yearlist;
          this.showSnackbar("red", res.data.msg);
        }
      });
    },
    save() {
      if(this.ay==""){
        this.showSnackbar("red","Select Academic Year...");
        return;
      }
      else if(this.from_date=="" || this.from_date==null){
        this.showSnackbar("red","Select From Date...");
        return;
      }
      else if(this.to_date==""){
        this.showSnackbar("red","Select To Date...");
        return;
      }
      else if(this.to_date < this.from_date){
        this.showSnackbar("red","Select valid To Date...");
        return;
      }
      else if(this.name==""){
        this.showSnackbar("red","Select Year...");
        return;
      }
      
      
      this.param.ay = this.ay;
      this.param.name = this.name;
      this.param.isactive = this.isactive;

      this.param.to_date = this.to_date;
      this.param.from_date = this.from_date;
      
      axios
        .post("/AdminMaster/saveOpenTpoRegistration", this.param)
        .then((res) => {
          if (res.data.status == "200") {
            this.showSnackbar("green", res.data.msg);
            this.init();
            this.dialog = false;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        });
      this.ay = "";
      this.name = "";
      this.from_date = new Date();
      this.to_date = new Date();
      this.isactive = false;
    },
    edit() {
      axios
        .post("/AdminMaster/editOpenTpoRegistration", this.param)
        .then((res) => {
          if (res.data.status == "200") {
            this.showSnackbar("green", res.data.msg);
            this.init();
            this.dialog1 = false;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        });
    },
    delete1(id) {
      if (confirm("Do you really want to delete?")) {
        var params = { id: id };
        axios
          .post("/AdminMaster/deleteOpenTpoRegistration", params)
          .then((res) => {
            if (res.data.status == "200") {
              this.showSnackbar("green", res.data.msg);
              this.init();
            } else {
              this.showSnackbar("red", res.data.msg);
            }
          });
      }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/AdminMaster/activeopentporeg", data)
        .then((res) => {
          // console.log("res.data");
          // console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
